import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const exportUpdateRequestForm = createAsyncThunk(
    "exportUpdate",
    async (values, { rejectWithValue }) => {
        console.log("values export update ==>", JSON.stringify(values));
        const token = window.localStorage.getItem("idToken");
        const response = await fetch(
            "https://6kgus2n703.execute-api.us-east-2.amazonaws.com/translationDev/Export/Export-Update-Form",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+token
                },
                body: JSON.stringify(values),
            }
        );

        try {
        
            const result = await response.json();
            console.log("result", result);
            return {
                statusCode: 200,
                message: "success",
                requestId: result.requestId
              };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
console.log("exportUpdateRequestForm", exportUpdateRequestForm);
const exportUpdateFormSlice = createSlice({
    name: "exportUpdate",
    initialState: {
        loading: false,
        error: '',
        isSuccess: false,
        data: [], 
        isError:false
    },
    extraReducers: {
        [exportUpdateRequestForm.pending]: (state) => {
            state.loading = true;
            state.isSuccess = false;
            state.data = [];
        },
        [exportUpdateRequestForm.fulfilled]: (state, action) => {
            console.log("export Report action", action.payload);

            if (action.payload.statusCode === 200) {
                state.loading = false;
                state.isError=false;
                state.error = '';
                state.isSuccess = true;
                state.data.push(action.payload);
            } else if([2001, 2002, 2003, 2004].includes(action.payload.statusCode)){
                state.loading = false;
                state.isError=true;
                state.error = "SERVER ERROR"
                state.isSuccess = false;
                state.data.push(action.payload);
            }
            else {
                state.loading = false;
                state.isError=true;
                state.error = action.payload;
                state.isSuccess = false;
                state.data.push(action.payload);
            }


        },
        [exportUpdateRequestForm.rejected]: (state, action) => {
            state.loading = false;
            state.isError=false;
            state.error = action.payload;
            state.isSuccess = false;
        }
    }
})

export default exportUpdateFormSlice.reducer
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const userRole = createAsyncThunk(
    "role",
    async (values, { rejectWithValue }) => {

        // const param = {
        //     "$count": "true",
        //     "$orderby": "displayName",
        //     "$filter": "startswith(displayName, 'Now_')",
        //     "$select": "displayName"
        // }
        const response = await fetch(
            "https://graph.microsoft.com/beta/users/" + values.username + "/transitiveMemberOf/microsoft.graph.group?count=true&orderby=displayName&filter=startswith(displayName, 'Now_')&select=displayName",
            {
                method: "GET",
                headers: {
                    "ConsistencyLevel": "eventual",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + values.access_token
                }
            }
        );

        try {
            const result = response.json();
             
            return result;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState: {
        loading: false,
        error: '',
        isSuccess: '',
        roles: []
    },
    extraReducers: {
        [userRole.pending]: (state) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [userRole.fulfilled]: (state, action) => {
            state.loading = false;
            state.isSuccess = true;
            console.log("state and action",state,"+",action);
            state.roles.push(action.payload);
        },
        [userRole.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isSuccess = false;
        }
    }


})

export default roleSlice.reducer
import { Link } from "react-router-dom";
import Breadcrumb from "../../../component/Breadcrumb";
import { MdKeyboardArrowRight } from "react-icons/md";
import translation from "../../../assets/images/translation.png";

export const TranslationReport = () => {
    return (
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Breadcrumb />
                        <div className="widget">
                            <div className="card-header">Reports</div>
                            <hr className="hr" />
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body-category">
                                            <img
                                                src={translation}
                                                alt="user_card"
                                                width="50"
                                                height="50"
                                            />
                                            <h5 className="text-muted">Translation Cycle Data</h5>
                                            <p className="card-text">
                                                List of all the export and import jobs run performed. Access and download export files, import files and error reports
                                            </p>
                                            <Link
                                                to="/translations/reports/historical-data"
                                                className="card-link"
                                            >
                                                View More
                                                <span>
                                                    <MdKeyboardArrowRight size={20} color="black" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body-category">
                                            <img
                                                src={translation}
                                                alt="user_card"
                                                width="50"
                                                height="50"
                                            />
                                            <h5 className="text-muted">Context File</h5>
                                            <p className="card-text">
                                                List of all the <b>Context Files</b> that were created,
                                                as a report request was submitted through use <b>Translation Request Form</b>
                                            </p>
                                            <Link to="/translations/reports/context-file" className="card-link">
                                                View More
                                                <span>
                                                    <MdKeyboardArrowRight size={20} color="black" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body-category">
                                            <img
                                                src={translation}
                                                alt="user_card"
                                                width="50"
                                                height="50"
                                            />
                                            <h5 className="text-muted">Debug</h5>
                                            <p className="card-text">
                                                The form provides list of reports that are generated as
                                                a areport request was submitted through use{" "}
                                                <b>Translation Request Form</b> - DEBUG button. An entry
                                                will qualify for translation if it meets below mentioned
                                                criteria.
                                            </p>
                                            <Link to="/translations/reports/export-report" className="card-link">
                                                View More
                                                <span>
                                                    <MdKeyboardArrowRight size={20} color="black" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body-category">
                                            <img
                                                src={translation}
                                                alt="user_card"
                                                width="50"
                                                height="50"
                                            />
                                            <h5 className="text-muted">Export</h5>
                                            <p className="card-text">
                                                The form provides list of reports that are generated, as
                                                a report request was submitted through use{" "}
                                                <b>Translation Request Form</b> - EXPORT button. An
                                                entry will qualify for traslation if it meets below
                                                mentioned criteria.
                                            </p>
                                            <Link to="/" className="card-link">
                                                View More
                                                <span>
                                                    <MdKeyboardArrowRight size={20} color="black" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body-category">
                                            <img
                                                src={translation}
                                                alt="user_card"
                                                width="50"
                                                height="50"
                                            />
                                            <h5 className="text-muted">Import</h5>
                                            <p className="card-text">
                                                List of reports on <b>Import</b> job run.
                                            </p>
                                            <Link to="/" className="card-link">
                                                View More
                                                <span>
                                                    <MdKeyboardArrowRight size={20} color="black" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { PublicClientApplication } from '@azure/msal-browser';
const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,// "776dc018-6a76-44c0-96b0-15d468272682", //dev client id
        authority: process.env.REACT_APP_AUTHORITY,// "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580",
        redirectUri: process.env.REACT_APP_REDIRECT_URI// "https://dev-now-ancillary.lilly.com/auth/callback", //dev cloudfront url
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        allowRedirectInIframe: true
    }
};
const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;

 

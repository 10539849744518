import React, { useEffect, useState } from 'react';
import Header from './component/Header/Header';
import Sidebar from './component/Sidebar/Sidebar';
import Footer from './component/Footer.js/Footer';
import Router from './routes/Router';
import { useMsal, useMsalAuthentication, useIsAuthenticated, useAccount } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { authAdded } from './redux/feature/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import _isContains from './component/utils/compareRole';
import Loader from './component/Common/Loader';


import LoggedOut from './model/loggedout';
import { configs } from 'triple-beam';



const App = () => {

  const [open, setOpen] = useState(true);

  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  dispatch(authAdded(account));
  const role = useSelector((state) => state.userRole);
  const [accessSnapshot, setAccessSnapshot] = useState(false);
  const switchValue = useSelector((state) => state.toggleSlice.active);
  const [loggedOutValue, setLoggedOutValue] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const [accessTranslation, setAccessTranslation] = useState(false);

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem('expireTime');
    if (expireTime < Date.now()) {
      console.log("Logout");
      setLoggedOutValue(true);
    }
  }

  const updateExpireTime = () => {
    const expireTime = Date.now() + 1800000; //30 minutes for session timeout
    localStorage.setItem("expireTime", expireTime);
  }


  useEffect(() => {
    const intervalTme = setInterval(() => {
      instance.acquireTokenSilent({
        scopes: ["offline_access"],
        account: accounts,
        forceRefresh: false
      }).then((response) => {
        console.log("afterrefreshToken", response)
        if (response) {
          window.localStorage.setItem("idToken", response.idToken);
          window.localStorage.setItem("silentTokenExpire", Date.now());
        }
      });
    }, 3300000); //55 minutes for refresh token API generate slient token. 

     // clean
    return () => clearInterval(intervalTme);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    // clean
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);
    // clean
    return () => {
      window.addEventListener("click", updateExpireTime);
      window.addEventListener("keypress", updateExpireTime);
      window.addEventListener("scroll", updateExpireTime);
      window.addEventListener("mousemove", updateExpireTime);
    }
  }, [])


  useEffect(() => {
    if (role.isSuccess) {
      const userRoles = role.roles[0].value;
      console.log("userRoles",userRoles)
      setAccessSnapshot(_isContains(userRoles, 'now_dev_snapshots'));
      setAccessTranslation(_isContains(userRoles, 'now_dev_snapshots'))
    }
  }, [role.isSuccess]);

  useEffect(() => {
    if (account) {
      console.log(account)
      dispatch(authAdded(account));
      instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account
      }).then((response) => {
        console.log("response results => ", response)
        if (response) {

          window.localStorage.setItem("idToken", response.idToken);
          window.localStorage.setItem("silentTokenExpire", Date.now());
        }
      });
    }
  }, [account, instance]);

  if (isAuthenticated) {
    return (
      <>
        {
          loggedOutValue && <LoggedOut isOpen={true} />
        }
        <div className='dashboard-main-wrapper'>

          <Header />
          <Sidebar open={open} setOpen={setOpen} isSnapshot={accessSnapshot} isTranslation={accessTranslation}/>
          <div className={`${switchValue ? "dashboard-wrapper-collapse" : "dashboard-wrapper"}`} >
            <Router isSnapshot={accessSnapshot} />
            <Footer />
          </div>
        </div >
      </>
    );
  } else {
    return <Loader />
  }

}

export default App;
